import React from "react";
import styled from "styled-components";

const ArrowButton = ({icon}) => {
  return (
    <Button aria-label="Navigate back" tabIndex="0">
     <img src={icon} alt="icon" />
    </Button>
  );
};

const Button = styled.button`
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.08);
  display: flex;
  width: 52px;
  height: 52px;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e7eb;
  cursor: pointer;

  &:focus {
    outline: 2px solid #4a90e2;
    outline-offset: 2px;
  }
`;

export default ArrowButton;