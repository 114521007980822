import React from 'react';
import './App.css';
import ChatbotInterface from './ChatbotInterface';

function App() {
  return (
    <div className="App">
      <ChatbotInterface />
    </div>
  );
}

export default App;