import React from "react";
import styled from "styled-components";
import ArrowButton from "./ArrowButton";

const ParagraphWriterCard = ({ desc, onClick, icon }) => {
  return (
    <Card onClick={onClick}>
      <Header>
        <ArrowButton icon={icon} />
      </Header>
      <Description>
        {desc}
      </Description>
    </Card>
  );
};

const Card = styled.article`
  border-radius: 20px;
  border: 1px solid var(--border, #e5e7eb);
  background-color: #fff;
  display: flex;
  max-width: 264px;
  flex-direction: column;
  padding: 20px;
  cursor: pointer;
`;

const Header = styled.header`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;


const Description = styled.p`
  color: var(--font-2, #838383);
  margin-top: 10px;
  flex: 1;
  font: 400 14px Arial, sans-serif;
`;

export default ParagraphWriterCard;