import { Box, Button, Container, Grid, Stack, TextField, Typography } from '@mui/material';
import Popover from '@mui/material/Popover';
import React, { useEffect, useRef, useState } from 'react';
import ParagraphWriterCard from './ParagraphWriter';


const serverIP = "https://genie-be.esenyurt.dhlrdlab.com"

const ChatbotInterface = () => {

  const readyQuestions = [
    'How should the battery charge level of a forklift be checked before operation, could you list the steps?',
    'What are the dangers of moving a forklift with the forks raised?',
    'Why is it prohibited to operate a forklift on wet or oily surfaces?',
    'How should a forklift operator warn people in the vicinity of a potential danger?',
    'What precautions should a forklift operator take when loading on a ramp?',
    'What should a forklift operator do when encountering a slope with a 15-degree incline?'
  ]

  const cardQuestions = [
    'How should the battery charge level of a forklift be checked before operation, could you list the steps?',
    'What are the dangers of moving a forklift with the forks raised?',
    'Why is it prohibited to operate a forklift on wet or oily surfaces?'
  ]

  const cardIcons = [
    '/Layer3.png',
    '/Layer4.png',
    '/Layer5.png'
  ]

  const [isRecording, setIsRecording] = useState(false);
  const [audioChunks, setAudioChunks] = useState([]);
  const [status, setStatus] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [inputText, setInputText] = useState('');
  const [audioUrl, setAudioUrl] = useState('');
  const [cardsOpen, setCardsOpen] = useState(true)

  const mediaRecorderRef = useRef(null);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const chatContainerRef = useRef(null);
  const videoRef = useRef(null);
  const audioRef = useRef(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatMessages]);

  useEffect(() => {
    initializeVideo();
    return () => {
      cleanUp();
    };
  }, []);

  const handleReadyQuestionClick = (question) => {
    setInputText(question)
    // sendQuestion(new Event('submit'))
  }

  const initializeVideo = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 30;
      videoRef.current.pause();
      videoRef.current.muted = true;  // Mute the video
    }
  };

  const cleanUp = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
    }
    if (audioContextRef.current) {
      audioContextRef.current.close();
    }
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.src = '';
    }
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 30;
    }
    setIsRecording(false);
    setStatus('');
    setAudioChunks([]);
    setAudioUrl('');
  };

  const toggleRecording = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const startRecording = () => {
    setAudioChunks([]);
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        const source = audioContextRef.current.createMediaStreamSource(stream);
        analyserRef.current = audioContextRef.current.createAnalyser();
        source.connect(analyserRef.current);
        analyserRef.current.fftSize = 256;

        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.start();

        mediaRecorderRef.current.ondataavailable = (event) => {
          setAudioChunks(chunks => [...chunks, event.data]);
        };

        mediaRecorderRef.current.onstop = () => {
          const audioBlob = new Blob(audioChunks, { 'type': 'audio/mp3' });
          const audio = new Audio(URL.createObjectURL(audioBlob));
          audio.play();
        };

        visualize();
        setIsRecording(true);
        setStatus('Recording...');
      })
      .catch(error => {
        console.error('Error accessing microphone:', error);
        setStatus('Error accessing microphone');
      });
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
      audioContextRef.current.close();
      setIsRecording(false);
      setStatus('Recording stopped');
    }
  };

  const sendAudio = () => {
    setStatus('Sending audio...');
    const audioBlob = new Blob(audioChunks, { type: 'audio/mp3' });
    const formData = new FormData();
    formData.append('audio_file', audioBlob, 'request_audio.mp3');
    formData.append('id', Date.now().toString());

    fetch(serverIP + '/audio_request', {
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          setStatus('Error: ' + data.error);
        } else {
          setInputText(data.transcript);
          setStatus('Audio processed successfully');
          if (data.audio_url) {
            const filename = data.audio_url.split('/').pop();
            setAudioUrl(serverIP + `/audio/${filename}`);
          }
        }
      })
      .catch(error => {
        console.error('Error:', error);
        setStatus('Error sending audio: ' + error.message);
      });
  };

  const sendQuestion = (event) => {
    event.preventDefault();
    setCardsOpen(false)
    if (!inputText.trim()) {
      setStatus('Please enter a question');
      return;
    }

    setChatMessages(messages => [...messages, { text: inputText, sender: 'user' }]);
    setStatus('Sending question...');

    const data = {
      id: Date.now().toString(),
      transcript: inputText,
      approved: 'True'
    };

    fetch(serverIP + '/chatbot_request', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok: ' + response.statusText);
        }
        return response.json();
      })
      .then(data => {
        console.log('Received data:', data);
        if (data.error) {
          setStatus('Error: ' + data.error);
        } else if (data.answer) {
          setChatMessages(messages => [...messages, { text: data.answer, sender: 'bot' }]);
          setStatus('Response received');

          if (data.audio_url) {
            const filename = data.audio_url.split('/').pop();
            setAudioUrl(serverIP + `/audio/${filename}`);
          }
        } else {
          setStatus('Received unexpected response format');
        }
      })
      .catch(error => {
        console.error('Error:', error);
        setStatus('Error receiving response: ' + error.message);
      });

    setInputText('');
  };

  const visualize = () => {
    if (!isRecording) return;
    requestAnimationFrame(visualize);

    const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount);
    analyserRef.current.getByteTimeDomainData(dataArray);

    let sum = 0;
    for (let i = 0; i < dataArray.length; i++) {
      sum += Math.abs(dataArray[i] - 128);
    }
    const average = sum / dataArray.length;
    const percentage = (average / 128) * 300; // Amplify the effect

    document.querySelector('#voiceBar div').style.width = `${Math.min(100, percentage)}%`;
  };

  const handleVideoPlayback = () => {
    const video = videoRef.current;
    const audio = audioRef.current;
    if (!video || !audio) return;

    video.currentTime = 35;
    video.play();

    video.addEventListener('timeupdate', loopBetweenSegments);
    audio.addEventListener('ended', handleAudioEnd);
    audio.addEventListener('pause', handleAudioPause);  // Stop video when audio is paused
  };

  const loopBetweenSegments = () => {
    const video = videoRef.current;
    if (video.currentTime >= 50) {
      video.currentTime = 35;
    }
  };

  const handleAudioEnd = () => {
    const video = videoRef.current;
    if (!video) return;

    video.removeEventListener('timeupdate', loopBetweenSegments);
    video.currentTime = 30;
    video.pause();
  };

  const handleAudioPause = () => {
    const video = videoRef.current;
    if (!video) return;

    video.pause();
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Container maxWidth='xl' sx={{ p: 5, borderRadius: 2, boxShadow: 3, bgcolor: '#FFFFFF' }}>
      <Grid container spacing={2} justifyContent='space-between' >
        <Grid xs={12} md={4}>
          <Box sx={{ borderRadius: 1, overflowY: 'hidden' }}>
            <video ref={videoRef} src="/videos/test_video.mp4" style={{ objectFit: 'cover', height: '50%', width: '60%' }} />
          </Box>
        </Grid>
        <Grid xs={12} md={8} sx={{ justifyContent: 'space-between', display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box>
            <Typography variant="h4" align="center" color="#E60000" fontWeight={'bold'}>DHL Genie</Typography>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, m: 1 }}>
                {readyQuestions.map((question, index) => (
                  <Button
                    key={index}
                    variant="outlined"
                    color="textPrimary"
                    sx={{ padding: 1, fontSize: 'small', lineHeight: '1', textTransform: 'none' }}
                    onClick={() => { handleReadyQuestionClick(question); handleClose() }}
                  >
                    {question}
                  </Button>
                ))}
              </Box>
            </Popover>
          </Box>
          <Stack flexWrap={'wrap'} flexDirection='row' justifyContent='space-around'>
              {cardsOpen && cardQuestions.map((question, index) => (
                <ParagraphWriterCard
                  key={index}
                  onClick={() => { handleReadyQuestionClick(question); setCardsOpen(false) }}
                  desc={question}
                  icon={cardIcons[index]}
                />
              ))}
            </Stack>
          <Box>
            {chatMessages.length > 0 && (
              <Box sx={{
                maxHeight: 380, overflowY: 'auto', p: 2, backgroundColor: 'transparent',
                '&::-webkit-scrollbar': {
                  width: '0.4em'
                },
                '&::-webkit-scrollbar-track': {
                  boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: 'rgba(0,0,0,.1)',
                  // outline: '1px solid grey'
                }
              }}>
                {chatMessages.map((message, index) => (
                  <Box key={index} sx={{ mb: 1, p: 1, borderRadius: 3, backgroundColor: message.sender === 'user' ? '#e6f3ff' : '#f0f0f0', textAlign: message.sender === 'user' ? 'end' : 'start' }}>
                    {message.text}
                  </Box>
                ))}
              </Box>
            )}
            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', m: 2 }}>
              <Button variant="contained" sx={{ backgroundColor: '#E60000' }} onClick={toggleRecording}>
                {isRecording ? 'Stop Recording' : 'Start Recording'}
              </Button>
              <Button variant="contained" sx={{ backgroundColor: '#E60000' }} onClick={sendAudio}>Send Audio</Button>
              <Button variant="contained" sx={{ backgroundColor: '#E60000' }} aria-describedby={id} onClick={handleClick}>FAQ</Button>
            </Box>

            <Box component="form" onSubmit={sendQuestion} sx={{ m: 2, display: 'flex', gap: 2, height: 'auto' }}  >
              <TextField
                variant="outlined"
                fullWidth
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                placeholder="Type your question here"


              />
              <Button type="submit" variant="contained" sx={{ mt: 1, backgroundColor: '#E60000' }}>Send</Button>
            </Box>
            <Typography variant="body1" align="center" sx={{ mt: 2, fontWeight: 'bold', color: status.includes('Error') ? 'error.main' : 'textPrimary' }}>
              {status}
            </Typography>
            {audioUrl && (
              <audio controls autoPlay src={audioUrl} onEnded={handleAudioEnd} ref={audioRef} onPlay={handleVideoPlayback} style={{ width: '90%', margin: '5px' }} />
            )}

          </Box>
        </Grid>
      </Grid>
    </Container >

  );
};


export default ChatbotInterface;